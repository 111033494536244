import axios from 'axios';
import ReactGA from 'react-ga';
import { SWRConfig } from 'swr';
import { Provider } from 'context/store';
import GlobalStyle from '../global-styles';
import { createBrowserHistory } from 'history';
import MetaHelmet from 'components/MetaHelmet';
import ScrollToTop from 'utils/hooks/scrollTo';
import { initTracking } from 'utils/analyticsUtil';
import React, { Suspense, lazy, useEffect } from 'react';
import { ThemeProvider } from 'context/theme/themeContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import VerifyOTPPage from './Auth/VerifyOTPPage';

const Promotions = lazy(() =>
  import(/* webpackChunkName: "Promotions" */ './Promotions')
);
const TicketOrder = lazy(() =>
  import(/* webpackChunkName: "TicketOrder" */ './TicketOrder')
);
const CheckoutPage = lazy(() =>
  import(/* webpackChunkName: "CheckoutPage" */ './CheckoutPage')
);
const PlayerContainer = lazy(() =>
  import(/* webpackChunkName: "Player" */ './PlayerContainer')
);
const EventItemPage = lazy(() =>
  import(/* webpackChunkName: "EventItemPage" */ './EventItemPage')
);
const MusicItemPage = lazy(() =>
  import(/* webpackChunkName: "EventItemPage" */ './MusicItemPage')
);
const StartSellingPage = lazy(() =>
  import(/* webpackChunkName: "StartSellingPage" */ './StartSellingPage')
);

export const Routes = lazy(() =>
  import(/* webpackChunkName: "Routes" */ './Routes')
);
export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "Login" */ 'containers/LoginPage')
);
export const SignUpPage = lazy(() =>
  import(/* webpackChunkName: "SignUpPage" */ 'containers/Auth/SignUpPage')
);
export const SignInPage = lazy(() =>
  import(/* webpackChunkName: "SignUpPage" */ 'containers/Auth/SignInPage')
);
export const ErrorBoundary = lazy(() =>
  import(/* webpackChunkName: "ErrorBoundary" */ 'containers/ErrorBoundary')
);
export const NewPasswdPage = lazy(() =>
  import(
    /* webpackChunkName: "NewPasswdPage" */ 'containers/Auth/NewPasswdPage'
  )
);
export const ResetPasswdPage = lazy(() =>
  import(
    /* webpackChunkName: "ResetPasswdPage" */ 'containers/Auth/ResetPasswdPage'
  )
);

//React GA
ReactGA.initialize('UA-122871506-1');
const history = createBrowserHistory();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

function App() {
  useEffect(() => {
    initTracking();

    if (window.location.hostname !== 'localhost') {
      trackPageview();
      history.listen(() => {
        trackPageview();
      });
    }
  }, []);

  function trackPageview() {
    ReactGA.pageview(window.location.pathname);
  }

  return (
    <Provider>
      <ThemeProvider>
        <SWRConfig
          value={{
            refreshInterval: 3000,
            fetcher: url => axiosInstance.get(url).then(res => res.data)
          }}
        >
          <Router>
            <Suspense fallback={<div>{null}</div>}>
              <ErrorBoundary>
                <MetaHelmet />
                <ScrollToTop updateWithPath />
                <Switch>
                  <Route exact path="/login" component={SignInPage} />
                  <Route exact path="/sign-up" component={SignUpPage} />
                  <Route
                    exact
                    path="/reset-password"
                    component={ResetPasswdPage}
                  />
                  <Route
                    exact
                    path="/password-confirm"
                    component={NewPasswdPage}
                  />
                  <Route
                    exact
                    path="/:id/verify-otp/"
                    component={VerifyOTPPage}
                  />
                  <Route
                    exact
                    path="/start-selling"
                    component={StartSellingPage}
                  />
                  <Route
                    exact
                    path="/embed/event/:slug"
                    component={EventItemPage}
                  />
                  <Route
                    exact
                    path="/embed/no-poster/event/:slug"
                    component={EventItemPage}
                  />
                  <Route
                    exact
                    path="/embed/album/:slug"
                    component={MusicItemPage}
                  />
                  <Route
                    exact
                    path="/embed/no-poster/album/:slug"
                    component={MusicItemPage}
                  />
                  <Route
                    exact
                    path="/embed/promo/:slug"
                    component={Promotions}
                  />
                  <Route
                    exact
                    path="/order_tickets/:id"
                    component={TicketOrder}
                  />
                  <Route
                    exact
                    path="/embed/checkout"
                    component={CheckoutPage}
                  />
                  <Route path="/" component={Routes} />
                  <Route path="/home" component={Routes} />
                </Switch>
                <PlayerContainer />
              </ErrorBoundary>
            </Suspense>
          </Router>
        </SWRConfig>
        <GlobalStyle />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
