import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { variables } from 'components/theme';

export const ButtonBase = styled(Button)`
  border: none;
  outline: none;
  min-height: 42px;
  letter-spaciong: 0.5px;
  ${variables.fonts.MTNBrighterSansExtraBold}
`;

export const MomoButton = ({ color, background, ...rest }) => {
  const textColour = useMemo(() => {
    switch (color) {
      case 'blue':
        return variables.colors.MOMO_BLUE;
      case 'grey':
        return variables.colors.MOMO_GREY;
      case 'white':
        return variables.colors.MOMO_WHITE;
      case 'yellow':
        return variables.colors.MOMO_YELLOW;
      default:
        return '#000';
    }
  }, [color]);

  const backgroundColour = useMemo(() => {
    switch (background) {
      case 'blue':
        return variables.colors.MOMO_BLUE;
      case 'grey':
        return variables.colors.MOMO_GREY;
      case 'white':
        return variables.colors.MOMO_WHITE;
      case 'light':
        return variables.colors.MOMO_GREY_TINT;
      case 'yellow':
        return variables.colors.MOMO_YELLOW;
      default:
        return variables.colors.MOMO_WHITE;
    }
  }, [background]);

  return (
    <ButtonBase
      {...rest}
      style={{
        color: textColour,
        background: backgroundColour
      }}
    />
  );
};

MomoButton.propTypes = {
  ...Button.propTypes,
  color: PropTypes.oneOf(['blue', 'grey', 'white', 'yellow']),
  background: PropTypes.oneOf(['blue', 'grey', 'white', 'light', 'yellow'])
};

export default MomoButton;
